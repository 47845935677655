import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3e7f326e = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4300e404 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _79ff85d7 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _39638e7a = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _2a9cf890 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e8be326 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _04d61ea9 = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _7d37f26d = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _7483c1f8 = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _48f20e2e = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _1db5fe6e = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _6f6f51fd = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _41a5d554 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _51a87c1e = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _2edca944 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _567e1a4c = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _e109a2e2 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _c66bd980 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _79eb4e93 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _64265854 = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _45d468b0 = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _03fcf3f7 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _7b7fe208 = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _7897bbf0 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _ae1860e4 = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _2862ea16 = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _778cff58 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _0a10fcb2 = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _85e8ebb0 = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _340a061b = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _39273841 = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _fbf8aeaa = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _3c9c67c2 = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _1527099f = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _c5e44382 = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _5dc14932 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _279310ae = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _0960bc42 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _18b8eb08 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _05ee45f8 = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _3e7f326e,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _4300e404,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _79ff85d7,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _39638e7a,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _2a9cf890,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _1e8be326,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _04d61ea9,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _7d37f26d,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _7483c1f8,
    name: "quality-guarantee___hu"
  }, {
    path: "/idopontfoglalas",
    component: _48f20e2e,
    name: "appointment-booking___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _1db5fe6e,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _6f6f51fd,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _41a5d554,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _51a87c1e,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _2edca944,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _567e1a4c,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _e109a2e2,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _c66bd980,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _79eb4e93,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _48f20e2e,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _79ff85d7,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _41a5d554,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _2edca944,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _7d37f26d,
    name: "dental-problems___en"
  }, {
    path: "/en/forgot-password",
    component: _39638e7a,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _6f6f51fd,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _51a87c1e,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _04d61ea9,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _64265854,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _4300e404,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _3e7f326e,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _7483c1f8,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _567e1a4c,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _79eb4e93,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _1e8be326,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _e109a2e2,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _c66bd980,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _45d468b0,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _03fcf3f7,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _7b7fe208,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _7897bbf0,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _ae1860e4,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _2862ea16,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _778cff58,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _0a10fcb2,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _85e8ebb0,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _2862ea16,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _0a10fcb2,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _ae1860e4,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _85e8ebb0,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _778cff58,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _7b7fe208,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _7897bbf0,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _45d468b0,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _03fcf3f7,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _340a061b,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _39273841,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _fbf8aeaa,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _3c9c67c2,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _1527099f,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _c5e44382,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _5dc14932,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _340a061b,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _39273841,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _5dc14932,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _279310ae,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _0960bc42,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _18b8eb08,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _1527099f,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _fbf8aeaa,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _c5e44382,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _3c9c67c2,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _05ee45f8,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _0960bc42,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _279310ae,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _18b8eb08,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _05ee45f8,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _2a9cf890,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
